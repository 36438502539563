
/*@media screen and (min-width: 991px) and (max-width: 1200px)  {*/
@media screen and (max-width: 1200px)  {

    /* COMMON */

    h2.header {
        font-size: 26px;
    }

    /* NAV MENU */

    .main.menu .nav-item {
        margin-right: 0 !important;
        padding-right: 4px !important;
    }

    .main.menu .nav-item > a {
        font-size: 14px;
    }

    /* MAIN VIEW */

    .phone-with-bot img {
        width: 100%;
        margin-top: -60px;
    }

    .main.grid > .column:first-child {
        margin-bottom: 0;
    }

    .utp.grid {
        margin-top: 86px;
    }

    .utp.grid .utp.column > div.utp {
        font-size: 14px;
    }

    .try-btn-container {
        margin-top: -20px;
    }

    .iphone-x-with-video-about-timebot {
        margin-top: 0;
    }

    /* EASY FOR CLIENT */

    img.easy-for-clients {
        width: 920px;
    }

    .easy-for-clients.grid .column .efc-item:first-child {
        padding-top: 48px;
    }

    .easy-for-clients.grid .column .efc-item:last-child {
        padding-top: 57px;
    }

    .easy-for-clients.grid .right.column {
        padding-left: 1.5rem !important;
    }

    .easy-for-clients.grid .right.column .efc-item > .efc-ordinal {
        left: 70px;
    }

    .connecting-line.first {
        margin-top: 30px;
    }

    .easy-for-clients.grid .column .efc-item > .efc {
        font-size: 14px;
    }

    /* EASY FOR SALON */

    img.easy-for-salon {
        width: 920px;
    }


    /* BONUS */

    .bonus-segment {
        padding-left: 130px;
    }

    .gift-img {
        width: 140px;
        margin-left: -166px;
        margin-top: 85px;
    }

    .bonus.grid .row .column {
        font-size: 14px;
    }

    /* FEEDBACKS */

    .feedbacks-container .slick-slide {
        padding: 0 20px;
    }

    /* CONTACTS */

    .contacts.grid .column .value {
        font-size: 30px;
    }

    .timebot-replica {
        top: 90px;
        right: 25px;
    }

}