
/* COMMON */

* {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.mobile-only {
    display: none !important;
}


.ui.vertical.segment {
    border-bottom: none !important;
}

.header {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 !important;
}

.header.secondary {
    font-weight: normal;
}

h2.header {
    font-size: 30px;
}

h3.header {
    font-size: 18px;
}

.main.header {
    text-align: center;
    color: #980E9D;
    background: -webkit-linear-gradient(45deg, #76007A, #980E9D 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h2.main.header {
    font-size: 56px;
}

h3.main.header {
    font-size: 28px;
}

.flex-row-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

span.emp {
    font-weight: 500;
    /*text-decoration: underline;*/
    /*color: #76007A;*/
}

/* NAV MENU */

.nav.sidebar.menu {
    /*background: #2a102b;*/
    background: white;
}

.nav.sidebar.menu .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    padding: 17px;
}

.nav.sidebar.menu .item img {
    margin-right: 16px;
    width: 22px !important;
    height: 22px !important;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.nav.sidebar.menu .item i {
    color: #876FC5;
    font-size: 22px;
    margin-right: 14px !important;
    margin-left: -2px !important;
}


.toc.item {
    display: none !important;
    color: #FF5964 !important;
}

.nav-menu.segment {
    padding: 0;
}

.img-logo {
    width: 215px !important;
}

.item.nav-item {
    padding-left: 5px !important;
}

.main.menu .nav-item > a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px !important;
    text-align: center;
    color: #525151;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.main.menu .nav-item > a > img {
    margin-right: 10px;
}

.main.menu .nav-item > a > i {
    color: #876FC5;
    font-size: 22px;
    margin-right: 8px;
}

.main.menu .nav-item > a:hover .nav-text {
    color: #FF5964;
    text-decoration: underline;
}

.main.menu .nav-item > a:active .nav-text {
    color: #EF4E59;
    text-decoration: underline;
}

.beauty-bot-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    background: #FF5964;
    border-radius: 30px;
    padding: 10px 40px;
    -webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.beauty-bot-btn:hover {
    cursor: pointer;
    color: #FFFFFF;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(255, 89, 100, 0.85);
}

.beauty-bot-btn:active {
    background: #EF4E59;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

.try-beauty-bot-btn {
    background: -webkit-linear-gradient(7.65deg, #FF5964 4.88%, #FF6D59 97.61%);
    background: -o-linear-gradient(7.65deg, #FF5964 4.88%, #FF6D59 97.61%);
    background: linear-gradient(82.35deg, #FF5964 4.88%, #FF6D59 97.61%);
    border: 1px solid #FF5964;
    -webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    padding: 16px 72px;
}

.try-beauty-bot-btn:hover {
    cursor: pointer;
    color: #FFFFFF;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: -webkit-linear-gradient(7.65deg, rgba(255, 89, 100, 0.9) 4.88%, rgba(255, 109, 89, 0.91) 97.61%);
    background: -o-linear-gradient(7.65deg, rgba(255, 89, 100, 0.9) 4.88%, rgba(255, 109, 89, 0.91) 97.61%);
    background: linear-gradient(82.35deg, rgba(255, 89, 100, 0.9) 4.88%, rgba(255, 109, 89, 0.91) 97.61%);
}

.try-beauty-bot-btn:active {
    background: #EF4E59;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

.beauty-bot-btn:hover {
    cursor: pointer;
    color: #FFFFFF;
}

/* MAIN VIEW */

.main.segment {
    background: -webkit-radial-gradient(87% 76%, 1246px, #76007A 0%, #920097 100%) !important;
    background: -o-radial-gradient(87% 76%, 1246px, #76007A 0%, #920097 100%) !important;
    background: radial-gradient(1246px at 87% 76%, #76007A 0%, #920097 100%) !important;
    padding-top: 55px;
}

.main.segment h1.secondary.header {
    color: white !important;
    margin-top: 14px !important;
    margin-bottom: 15px !important;
}

.main.segment h2.header {
    color: white !important;;
}

.main.grid > .column:first-child {
    margin-bottom: 120px;
}

.utp.grid {
    margin-top: 46px;
}

.utp.grid .row {
    /*margin-bottom: 32px;*/
    margin-bottom: 10px;
}

.utp.grid .utp.column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 60px;
}

.utp.grid .utp.column > img {
    height: 33px;
    max-width: 35px;
    margin-bottom: 15px;
}

.utp.grid .utp.column > div.utp {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
}

.phone-with-bot img {
    margin-top: -90px;
    margin-bottom: -30px;
}

.try-btn-container {
    margin-top: -100px;
    margin-bottom: 80px;
}

/* EASY FOR CLIENT */

.easy-for-client.segment {
    padding-top: 60px;
    padding-bottom: 0;
}

img.easy-for-clients {
    position: absolute;
    top: 85px;
}

.easy-for-clients.grid .column .efc-item:first-child {
    padding-top: 88px;
}

.easy-for-clients.grid .column .efc-item:last-child {
    padding-top: 86px;
}

.easy-for-clients.grid .column .efc-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


.easy-for-clients.grid .right.column {
    padding-left: 90px !important;
    padding-right: 0 !important;
}

.easy-for-clients.grid .column .efc-item > img.efc {
    margin-right: 40px;
    z-index: 10;
    width: 35px;
    height: 35px;
}

.easy-for-clients.grid .column .efc-item > img.efc.smaller {
    height: 30px;
}

.easy-for-clients.grid .column .efc-item > .efc-ordinal {
    position: relative;
    top: -25px;
    left: -27px !important;
    width: 0;
    height: 83px;
    font-weight: bold;
    font-size: 92px;
    line-height: 129px;
    text-align: center;
    color: #F1F1F1;
    z-index: 5;
}

.easy-for-clients.grid .right.column .efc-item > .efc-ordinal {
    left: 132px;
}

.easy-for-clients.grid .column .efc-item > .efc {
    font-size: 16px;
    line-height: 23px;
    color: #525151;
    z-index: 10;
}

.link-to-bot-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}



.timebot-in-chain {
    width: 245px;
    height: 275px;
    background-image: url("../images/link-to-bot-2.svg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.timebot-in-chain:hover {
    background-image: url("../images/link-to-bot-2-active.svg");
    cursor: pointer;
}

.bot-replica-container {
    position: absolute;
    width: 200px;
    /*height: 0 !important;*/
    margin-left: 200px;
}

.bot-replica-cloud {
    position: relative;
    width: 200px;
    top: -70px;
    right: 60px;
}

.timebot-replica-in-cloud {
    position: relative;
    top: -195px;
    left: -39px;
    width: 170px;
    /*font-weight: bold;*/
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    /*color: #FF5964;*/
    color: rgba(0, 0, 0, 0.9);
    height: 0;
}

.timebot-in-chain:hover .timebot-replica-in-cloud {
    color: #E91E63;
}

.connecting-line {
    height: 60px;
    width: 1px;
    margin-left: auto;
    margin-right: auto;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(33%, #876FC5), color-stop(0%, rgba(255,255,255,0)));
    background-image: -webkit-linear-gradient(#876FC5 33%, rgba(255,255,255,0) 0%);
    background-image: -o-linear-gradient(#876FC5 33%, rgba(255,255,255,0) 0%);
    background-image: linear-gradient(#876FC5 33%, rgba(255,255,255,0) 0%);
    background-position: right;
    background-size: 2px 6px;
    background-repeat: repeat-y;
}

.connecting-line.first {
    margin-top: 40px;
}

.connecting-line.second {
    margin-bottom: 10px;
}

/* EASY FOR SALON */

.easy-for-salon.segment {
    padding-top: 0;
    margin-top: -20px !important;
}

img.easy-for-salon {
    position: absolute;
    top: 25px;
}

.efs-btn-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 40px;
}

/* PRICES */

.prices.segment {
    padding-top: 64px;
    padding-bottom: 84px;
}

p.discount-text {
    margin-top: 16px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #FF5964;
}

.prices.grid {
    margin-top: 30px;
}

.prices.card {
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08) !important;
    border-radius: 16px;
    max-width: 350px !important;
    margin: auto;
}

.prices.card .content:first-child {
    padding: 0;
}

.prices.card .content:first-child .header {
    height: 52px;
    padding-top: 14px;
    background-image: url("../images/price-card-header-bg.svg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-family: 'Comfortaa', cursive !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #FFFFFF;
    text-transform: none;
}

.prices.card .content:first-child .period.buttons {
    margin-top: 32px;
    padding: 0 32px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.period.buttons button {
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #DADADA !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 14px !important;
    /*font-family: Rubik;*/
    font-family: 'Comfortaa', cursive !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
}

.period.buttons button.active,
.period.buttons button.active:hover {
    z-index: 40 !important;
    height: 48px;
    background: #76007A;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08) !important;
    border-radius: 14px !important;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center !important;
}

.period.buttons button:not(:first-child) {
    margin-left: -15%;
}

.period.buttons button:first-child {
    z-index: 20;
    text-align: left;
}

.period.buttons button:not(:first-child):not(:last-child) {
    z-index: 30;
    text-align: left;
    border-radius: 0 !important;
}

.period.buttons button.active:not(:first-child):not(:last-child) {
    border-radius: 14px !important;
}

.period.buttons button:last-child {
    z-index: 10;
    text-align: right;
}

.period.buttons button.active + button,
.period.buttons button.active + button + button {
    text-align: right !important;
}

.extra.content.discount-content {
    border-top: none !important;
    padding-top: 16px;
    padding-bottom: 30px;
}

.extra.content.discount-content .discount.label{
    background: -webkit-linear-gradient(7.65deg, #FF5964 4.88%, #FF6D59 97.61%);
    background: -o-linear-gradient(7.65deg, #FF5964 4.88%, #FF6D59 97.61%);
    background: linear-gradient(82.35deg, #FF5964 4.88%, #FF6D59 97.61%);
}

.extra.content.masters-count-content {
    border-top: none !important;
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: -15px;
}

.masters.statistics {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


.masters.statistics > .statistic {
    margin-bottom: 0;
}

.masters.statistics > .statistic > .value {
    font-weight: normal;
    font-size: 24px;
    color: #525151;
}

.masters.statistics > .statistic > .label {
    font-weight: normal;
    font-size: 16px;
    color: #525151;
    text-transform: lowercase;
}

.masters.statistics button.circular.button {
    background: #76007A;
    color: white;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    /*box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);*/
}

.masters.statistics button.circular.button:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(118, 0, 122, 0.85);
    /*box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);*/
}

.masters.statistics button.circular.button:active {
    background: #FF5964;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    /*box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);*/
}


.extra.content.pack-list-content {
    border-top: none !important;
    padding-left: 38px;
    padding-top: 20px;
}

.pack-list-content .list .item {
    padding-bottom: 20px;
}

.pack-list-content .list .item > i {
    font-size: 16px;
    color: #FF5964;
}

.pack-list-content .list .item > .content {
    padding-left: 20px;
    font-size: 16px;
    color: #525151;
}

.extra.content.prices-content {
    border-top: none !important;
    padding-top: 30px;
}

.prices.statistics {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /*align-items: flex-end;*/
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.prices.statistics .statistic {
    margin: 0;
}

.prices.statistics .old.statistic {
    margin-right: 8px;
}

.prices.statistics .new.statistic {
    margin-left: 8px;
}

.prices.statistics .label {
    display: none;
}

.prices.statistics .old.statistic div.value {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #9F9F9F;
}

.prices.statistics .old.statistic span.price {
    font-size: 18px;
}

.prices.statistics .old.statistic span.currency {
    font-size: 12px;
}

/*.prices.statistics .old.statistic .value:before {*/
/*content: " ";*/
/*display: block;*/
/*width: 100%;*/
/*border-top: 2px solid #db2828;*/
/*position: relative;*/
/*bottom: 0;*/
/*left: 0;*/
/*transform: rotate(-10deg);*/
/*margin-bottom: -20px;*/
/*}*/

.prices.statistics .old.statistic .value:before {
    content: " ";
    display: block;
    width: 100%;
    border-top: 1px solid #9F9F9F;
    position: relative;
    bottom: 0;
    left: 0;
    -webkit-transform: rotate(-0deg);
    -ms-transform: rotate(-0deg);
    transform: rotate(-0deg);
    margin-bottom: -19px;
}


.prices.statistics .new.statistic div.value {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #76007A;
}

.prices.statistics .new.statistic span.price {
    font-size: 36px;
}

.prices.statistics .new.statistic span.currency {
    font-size: 28px;
}

.extra.content.try-button {
    border-top: none !important;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 32px;
    padding-right: 32px;
}

.extra.content.try-button .try-beauty-bot-btn {
    padding: 16px;
    text-transform: uppercase;
}

/* BONUS */

.bonus-segment {
    margin-top: 86px;
    padding-top: 44px;
    padding-left: 230px;
    padding-bottom: 44px;
    background: -webkit-radial-gradient(87% 76%, 703px, #76007A 0%, #920097 100%);
    background: -o-radial-gradient(87% 76%, 703px, #76007A 0%, #920097 100%);
    background: radial-gradient(703px at 87% 76%, #76007A 0%, #920097 100%);
    border-radius: 16px;
}

.bonus-segment h2.header {
    color: #FFFFFF;
    font-size: 28px;
    margin-bottom: 30px !important;
}

.gift-img {
    position: absolute;
    margin-left: -266px;
    margin-top: 16px;
}

.bonus.grid {
    padding-left: 10px;
}

.bonus.grid .row .column {
    color: #FFFFFF;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.bonus.grid .row .column i {
    font-size: 8px;
    margin-right: 16px;
}

/* FEEDBACKS */

.feedbacks.segment {
    background: -webkit-radial-gradient(87% 76%, 1246px, #76007A 0%, #920097 100%) !important;
    background: -o-radial-gradient(87% 76%, 1246px, #76007A 0%, #920097 100%) !important;
    background: radial-gradient(1246px at 87% 76%, #76007A 0%, #920097 100%) !important;
    padding-top: 55px;
    padding-bottom: 54px;
}

.feedbacks.segment h2.header {
    text-align: center;
    text-transform: none;
    font-weight: bold;
    font-size: 48px;
}

.feedbacks-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 40px 0 30px;
}

.feedbacks-container i {
    width: 40px;
    font-size: 24px;
    display: none;
}

.feedbacks-container i:hover {
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer !important;
}

.feedbacks-container i:active {
    color: #FF5964;
}

.feedbacks-container .slick-slide {
    padding: 0 40px;
}

.feedback.card {
    padding: 0 !important;
    height: 525px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.feedback.card .heading-content {
    padding: 14px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.feedback.card .instagram.meta,
.feedback.card .instagram.meta i {
    width: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.feedback.card .instagram.meta i:hover {
    color: #EF4E59;
}

.feedback.card .instagram.meta i:active {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

.feedback.card .avatar.image {
    margin-right: 16px;
}

.feedback.card .header-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.feedback.card .header-container .name-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.feedback.card .header-container .name-container .name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4F4F4F;
    margin-bottom: 4px;
}

.feedback.card .header-container .name-container .salon-name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #979797;
}

.feedback.card .feedback-text {
}

.feedback.card .read-more.content,
.feedback.card .collapse.content {
    border-top: none !important;
}

.feedback.card .read-more-link,
.feedback.card .collapse-link {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #0087FF !important;
    text-transform: lowercase;
    cursor: pointer !important;
}

.feedback.card .read-more-link:hover,
.feedback.card .collapse-link:hover {
    text-decoration: none;
}

.feedback.card .read-more-link:active,
.feedback.card .collapse-link:active {
    color: #EF4E59 !important;
}

.feedback.card .cover.image {
    margin-top: auto;
}

.slick-dots li button:before {
    color: white !important;
    opacity: 0.5;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
}

.more-feedbacks-text {
    margin-top: 50px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
}

.instagram-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.instagram-link i {
    margin-right: 16px;
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.instagram-link a {
    margin-bottom: 7px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #FFFFFF;
}

.instagram-link a:hover {
    -webkit-text-decoration-line: none;
    text-decoration-line: none;
}

.instagram-link a:active {
    color: #FF5964;
}


/* CONTACTS */

.contacts.segment {
    padding-top: 60px;
    padding-bottom: 30px;
}

.contacts.segment h3.header.secondary {
    font-size: 20px;
    text-align: center;
    color: #000000;
    text-transform: none;
}

.contacts.segment h2.header {
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #76007A;
    /*background: -webkit-linear-gradient(45deg, #76007A, #980E9D 80%);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
}

.contacts.grid {
    margin-top: 10px;
    padding: 20px;
}

.contacts.grid .column {
    padding-left: 40px !important;
}

.contacts.grid .column .value {
    font-family: 'Signika', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: #76007A;
    margin-bottom: 10px;
}

.contacts.grid .column .label {
    font-family: 'Signika', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #999999;
}

.social-link {
    background-color: #76007A !important;
    color: white !important;
    margin-right: 16px !important;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12) !important;
}

.social-link:hover {
    background-color: rgba(118, 0, 122, 0.85) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.social-link:active {
    background: #FF5964 !important;
}


.request-form-column {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.request-form-container {
    width: 100%;
    background: #FFFFFF;
    -webkit-box-shadow: 0 12px 36px rgba(0, 0, 0, 0.08);
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 0;
}

iframe#bx_form_iframe_7 {
    height: 350px !important;
}

.request-form-container h3.header {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    color: #76007A;
}

.request-form-container .form {
    margin-top: 22px;
    margin-bottom: 51px;
}

img.timebot-with-replica {
    width: 100%;
}

.timebot-replica {
    position: absolute !important;
    top: 64px;
    right: 48px;
    width: 170px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #76007A;
}

.copyright-row {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.documents-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.documents-links a {
    margin-right: 30px;
    font-family: 'Signika', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #999999;
}

.documents-links a:hover {
    -webkit-text-decoration-line: none;
    text-decoration-line: none;
}

.documents-links a:active {
    color: #76007A
}

.copyright {
    font-family: 'Signika', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    color: #999999;
}

.social-links.value {
    margin-top: 14px;
}


.contact-form.grid .timebot-column {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}



/* ROUND BUTTON */

.round-button-flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.round-button-container {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 50%;
    /*border: 1px solid #980E9D;*/
    border: 4px solid #FF5964;
    padding: 0 !important;
    background: transparent;

    -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);

    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.round-button-container:hover {
    /*border: 1px solid #980E9D;*/
    border: 4px solid #FF5964;
    -webkit-box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
}

.round-button-container:active {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
}

.round-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 200px;
    width: 200px;
    padding: 0 !important;

    border-radius: 50%;
    /*border: 5px solid #f5f5f5;*/
    border: 1px solid rgba(223, 223, 223, 0.22);
    /*border: 5px solid #980E9D;*/
    overflow: hidden;
    /*background: #DFDFDF;*/
    background: #dfdfdf94;
    /*box-shadow: 0 0 5px gray;*/
}

.round-button:hover {
    /*background: #dfdfdf94;*/
    background: rgba(223, 223, 223, 0.22);
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer !important;
    border: 1px solid #FF5964;
}

.round-button:active {
    background: rgba(223, 223, 223, 0.22);
}

.round-button a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.round-button img {
    height: 60% !important;
    width: 60% !important;
}



/* FORM */

.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus {
    border-color: #876FC5;
    color: #6D41A1;
}


/* SCROLL TOP */

#go-top {
    position: fixed;
    bottom: 35px;
    right: 35px;
    cursor: pointer;
    display: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 9999999;
    opacity: 0.8;

    background-image: url("../images/up-arrow.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    /*box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);*/
    /*border: 2px solid #808080c2;*/
}

#go-top:hover {
    opacity: 0.5;
    cursor: pointer;
    /*box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);*/
}

#go-top:active {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    /*box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);*/
}


/* PROMO VIDEO */

.promo-video.segment {
    background-color: #F8F8F8 !important;
    padding-top: 64px;
    padding-bottom: 64px;
}

.promo-video-container {
    margin-top: 40px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

#promo-video {
    width: 100%;
}

#promo-video > .icon:hover {
    border: 1px solid #FF5964;
}

#promo-video i.icon.video.play:before {
    color: #FF5964 !important;
    opacity: 0.3;
}

#promo-video:hover i.icon.video.play:before {
    color: #FF6D59 !important;
    opacity: 1;
}


/* MAIN VIDEO */

#main-video {
    position: initial !important;
    background: transparent !important;
    height: 100%;
    margin-top: -50px;
}

#main-video > .icon:after {
    background: transparent;
}

#main-video > .icon:hover:after {
    background: -webkit-radial-gradient(transparent 45%,rgba(0,0,0,.3));
    background: -o-radial-gradient(transparent 45%,rgba(0,0,0,.3));
    background: radial-gradient(transparent 45%,rgba(0,0,0,.3));
}

#main-video > .icon:hover {
    /*border: 1px solid #29abe2;*/
    border: 1px solid #FF5964;
}

#main-video i.icon.video.play:before {
    /*color: #62afeb !important;*/
    color: #FF5964 !important;
    opacity: 0.5;
}

#main-video:hover i.icon.video.play:before {
    /*color: #29c8ff !important;*/
    color: #FF6D59 !important;
    opacity: 1;
}

#main-video img.placeholder {
    top: 10px !important;
    margin-top: 0;
}

.iphone-x-with-video-about-timebot {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    /*background-image: url("http://localhost:63342/timebook/web_main/landing/images/iphone-x-with-video-about-timebot-4.png");*/
    background-image: url("../images/iphone-x.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    /*background-size: cover; !* Resize the background image to cover the entire container *!*/
    border-radius: 9%;
    width: 244px;
    height: 490px;
    z-index: 999;
    box-shadow: 0 13px 65px rgba(0, 0, 0, 0.3);
    padding: 0 !important;
    margin-top: -60px;
}

.iphone-x-video-player {
    position: absolute;
    margin-top: 46px;
    margin-left: 11px;
    border-radius: 3px;
    overflow: hidden;
    line-height: 0;
    width: 90%;
    height: 80%;
    background: white;
}

.mobile-login-btn {
    color: rgba(255, 89, 100, 0.85);
    text-decoration: underline;
    font-weight: bold
}

.mobile-login-btn:active {
    color: rgba(255, 89, 100, 0.85);
}

.mobile-login-btn:hover {
    color: rgba(255, 89, 100, 0.85);
}