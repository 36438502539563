
/*@media screen and (min-width: 767px) and (max-width: 991px) {*/
@media screen and (max-width: 991px) {

    .toc.item {
        /*margin-left: -10px !important;*/
        display: block !important;
    }

    .toc.item i {
        margin-right: 0 !important;
    }

    .item.nav-item {
        display: none !important;
    }

    h2.main.header {
        font-size: 42px;
    }

    /* MAIN VIEW */

    .main.segment {
        padding-top: 40px;
    }

    .main.grid {
        margin-top: 0 !important;
    }

    .utp.grid {
        margin-top: 30px;
    }

    .utp.grid .row {
        margin-bottom: 20px;
    }

    .utp.grid .utp.column {
        padding-bottom: 50px;
        padding-left: 40px !important;
        padding-right: 0 !important;
    }

    .phone-with-bot img {
        margin-top: 0;
    }

    .try-btn-container {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    /* EASY FOR CLIENT */

    img.easy-for-clients {
        width: 720px;
    }

    .easy-for-clients.grid .column .efc-item:first-child {
        padding-top: 38px;
    }

    .easy-for-clients.grid .column .efc-item:last-child {
        padding-top: 25px;
    }

    .easy-for-clients.grid .column .efc-item > img.efc {
        margin-right: 24px;
        width: 26px;
    }

    .easy-for-clients.grid .column .efc-item > .efc {
        font-size: 12px;
    }

    .easy-for-clients.grid .column .efc-item > .efc-ordinal {
        font-size: 82px;
    }

    .easy-for-clients.grid .column {
        padding-right: 0 !important;
    }

    .easy-for-clients.grid .column .efc-item > .efc-ordinal {
        left: -15px !important;
    }

    .connecting-line.first {
        margin-top: 16px;
    }

    .timebot-in-chain {
        width: 205px;
        height: 230px;
    }

    /* EASY FOR SALON */

    img.easy-for-salon {
        width: 720px;
    }

    .efs-btn-container {
        margin-top: 80px;
    }

    /* GIFT */

    .bonus-segment {
        padding-left: 100px;
    }

    .gift-img {
        width: 100px;
        margin-left: -120px;
        margin-top: 162px;
    }

    /* FEEDBACKS */

    .feedbacks.segment h2.header {
        font-size: 30px;
    }

    .feedbacks-container i {
        display: none;
    }

    /* CONTACTS */

    .contacts.grid .column .value {
        font-size: 24px;
    }

    .social-links.value {
        margin-top: 0;
    }

    .request-form-container h3.header {
        font-size: 14px;
    }

    img.timebot-with-replica {
        width: 80%;
    }

    .timebot-replica {
        top: 126px;
        right: 32px;
        width: 130px;
        font-size: 10px;
        line-height: 16px;
    }


    /* MAIN VIDEO */

    #main-video {
        margin-top: 0;
    }

}