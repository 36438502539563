
@media screen and (max-width: 767px) {

    .mobile-only {
        display: initial !important;
    }

    .mobile-only.flex {
        display: flex !important;
    }

    .mobile-hidden {
        display: none !important;
    }

    .login.item {
        /*display: none !important;*/
    }

    .login.item .beauty-bot-btn {
        padding: 10px 20px !important;
        font-size: 14px !important;
    }

    .img-logo {
        width: 150px !important;
    }


    h3.header {
        font-size: 16px;
    }

    h2.header {
        font-size: 22px;
    }

    h3.main.header {
        font-size: 22px;
    }

    .try-beauty-bot-btn {
        padding: 12px 12px;
        width: 100%;
        max-width: 360px;
        margin: auto;
    }

    /* MAIN VIEW */

    .main.segment {
        background: -webkit-radial-gradient(86.74% 76.49%, 1246.16px, #920097 0%, #76007A 100%) !important;
        background: -o-radial-gradient(86.74% 76.49%, 1246.16px, #920097 0%, #76007A 100%) !important;
        background: radial-gradient(1246.16px at 86.74% 76.49%, #920097 0%, #76007A 100%) !important;
    }

    .main.segment h1.secondary.header,
    .main.segment h2.header {
        width: 100%;
        text-align: center;
    }

    .main.segment h1.secondary.header {
        line-height: 30px;
        margin-bottom: 30px !important;
    }

    .main.segment h2.header {
        line-height: 43px;
    }

    .phone-with-bot img {
        max-height: 600px;
    }

    .utp.grid {
        margin-top: 0;
    }

    .utp.grid .utp.column {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .utp.grid .utp.column > img {
        margin-left: 40px;
        margin-right: 24px;
    }

    .utp.grid .utp.column > div.utp {
        margin-top: -5px;
    }

    .try-btn-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 48px;
        margin-bottom: 48px;
    }

    .iphone-x-with-video-about-timebot {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    /* EASY FOR CLIENT */

    h2.main.header {
        font-size: 23px;
    }

    .easy-for-clients.grid {
        margin-top: 10px;
    }

    .easy-for-clients.grid .column .efc-item {
        padding-top: 16px !important;
    }

    .ui.container > .ui.stackable.easy-for-clients.grid .left.column,
    .ui.container > .ui.stackable.easy-for-clients.grid .right.column {
        padding: 0 0 0 40px !important;
    }

    .easy-for-clients.grid .column .efc-item > .efc {
        font-size: 14px;
    }

    .easy-for-clients.grid .column .efc-item > img.efc {
        margin-right: 32px;
    }

    .easy-for-clients.grid .column .efc-item > .efc-ordinal {
        left: -20px !important;
    }

    .link-to-bot-container.mobile-only {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .timebot-in-chain {
        width: 105px;
        height: 115px;
    }

    .connecting-line {
        height: 30px;
    }

    .connecting-line.first {
        margin-top: 0;
    }

    .connecting-line.second {
        margin-bottom: 0;
    }

    .round-button-flex-container {
        height: 132px;
    }

    .round-button-container {
        margin-left: 0;
    }

    .round-button {
        height: 130px;
        width: 130px;
    }

    .bot-replica-cloud {
        width: 140px;
        right: 79px;
        top: -47px;
    }

    .timebot-replica-in-cloud {
        top: -136px;
        left: -72px;
        width: 140px;
        font-size: 10px;
        line-height: 14px;
    }

    /* EASY FOR SALON */

    .easy-for-salon.segment {
        margin-top: 40px !important;
    }

    .efs-btn-container {
        margin-top: 40px;
        margin-left: 20px;
        margin-right: 20px;
    }

    /* PRICES */

    p.discount-text {
        font-size: 12px;
    }

    /* BONUS */

    .bonus-segment h2.header {
        font-size: 22px
    }

    .bonus-segment {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 120px;
    }

    .gift-img {
        width: 100px;
        margin-left: 0;
        bottom: 66px;
    }

    /* FEEDBACKS */

    .feedbacks-container {
        margin-left: 20px;
        margin-right: 20px;
    }

    .feedbacks-container .slick-slide {
        padding: 5px;
    }

    .feedback.card {
        margin: auto;
    }

    .more-feedbacks-text {
        font-size: 14px;
    }

    .ui.stackable.feedbacks.grid .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    /* CONTACTS */

    .contacts.segment h2.header {
        font-size: 22px;
        margin-top: 30px !important;
    }

    .contacts.grid .column {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    #timebot-column {
        display: none !important;
    }

    img.timebot-with-replica {
        width: 70%;
        margin-bottom: 30px;
    }

    .timebot-replica {
        top: 36px;
        right: 38px;
        line-height: 14px;
    }

    .request-form-container {
        /*padding: 0 0 30px 0;*/
        padding: 0;
    }

    .request-form-container h3.header {
        line-height: 22px;
    }

    .request-form-container .form {
        margin-bottom: 20px;
    }

    .request-form-container .try-beauty-bot-btn {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .copyright-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .documents-links {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .documents-links a {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .copyright {
        margin-top: 20px;
    }


    #messages {
        min-width: 300px !important;
    }

    .promo-video-container {
        width: 90%;
    }
}