/*@font-face {*/
/*font-family: "Roboto-Regular";*/
/*src: url(/index-resources/fonts/roboto/Roboto-Regular.ttf);*/
/*}*/

/*@font-face {*/
/*font-family: "Roboto-Bold";*/
/*src: url(/index-resources/fonts/roboto/Roboto-Bold.ttf);*/
/*}*/

/*@font-face {*/
/*font-family: "Roboto-Light";*/
/*src: url(/index-resources/fonts/roboto/Roboto-Light.ttf);*/
/*}*/

/*@font-face {*/
/*font-family: "Candara";*/
/*src: url(/index-resources/fonts/Candara.ttf);*/
/*}*/

/*@font-face {*/
/*font-family: "Candara-Italic";*/
/*src: url(/index-resources/fonts/Candara-Italic.ttf);*/
/*}*/

/** {*/
/*font-family: "Roboto-Regular", Arial, sans_serif, serif;*/
/*}*/


/*#main-container * {*/
/*font-family: 'Roboto', sans-serif;*/
/*}*/

/*#main-container i {*/
/*font-family: 'Open Sans', sans-serif;*/
/*}*/

#logout {

}

body {
    /*font-family: 'Open Sans', sans-serif;*/
    font-family: 'Roboto', sans-serif;
}

.ui.card>.content>.header, .ui.cards>.card>.content>.header {
    font-family: 'Roboto', sans-serif;
}

main {
    height: 100%;
}

@media only screen and (min-width: 1025px) {
    main.main.segment > div.ui.container,
    .pagehead.segment > div.ui.container,
    .pagehead.segment > .ui.menu > div.ui.container,
    .steps-and-actions > div.ui.container {
        width: 85%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    main.main.segment > div.ui.container,
    .pagehead.segment > div.ui.container,
    .pagehead.segment > .ui.menu > div.ui.container,
    .steps-and-actions > div.ui.container {
        width: 95%;
    }
}

.ui.container {
    margin: auto;
}

.auto-init {

}

.main-content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.full.height > .toc {
    position: fixed;
    height: 100%;
    z-index: 1;
    width: 250px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #421214;
}

.full.height > .article {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    margin-left: 250px;
}

.full.height > .toc .ui.menu {
    border-radius: 0;
    border-width: 0 1px 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    width: inherit;
    will-change: transform;
    max-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: transparent;

}

.full.height .ui.menu .logo.image {
    width: 35px;
    margin-right: 1em;
}

.main-nav-bar {
    background-color: #421214 !important;
}

.medium-logo {
    height: 35px;
    width: 35px;
}

.logo-container {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 35px;
}

#main-sticky-header {
    left: auto;
    width: calc(100% - 250px);
    height: 61px;
    border-left: 1px dimgrey solid;
}

.tab.pagehead.segment {
    display: block;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) inset;
    border-bottom: transparent !important;
}

.pagehead.segment {
    z-index: 3;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.main.segment {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 !important;
    flex: 1 !important;
}

.list-page.segment {
    min-height: 500px;
}


.ui.form .required.fields:not(.grouped) > .field > label:after, .ui.form .required.fields.grouped > label:after, .ui.form .required.field > label:after, .ui.form .required.fields:not(.grouped) > .field > .checkbox:after, .ui.form .required.field > .checkbox:after {
    color: #DB2828;
}

#main-datepicker .ui-datepicker-prev,
#main-datepicker .ui-datepicker-next {
    color: white;
    right: 1px;
    top: 1px;
}

#main-datepicker .ui-datepicker-prev:hover,
#main-datepicker .ui-datepicker-next:hover {
    color: grey;
    top: 1px;
    right: 1px;
    background-color: transparent;
}

.table-cell-link {
    color: #9E0909;
}

.table-cell-link:hover {
    color: #ffab00
}

.light.header {
    font-weight: normal;
}

.hidden {
    display: none !important;
}

.invisible {
    visibility: hidden;
}

.invisible.hide-when-invisible {
    display: none !important;
}

.force-display-block {
    display: block !important;
}

.tab-row {
    margin-top: 2em;
    margin-bottom: 1em;
}

.flex-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.flex-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.flex-center {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.flex-center.hidden {
    display: none !important;
}

.flex-column-center {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-column-left {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
}

.flex-align-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-space-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-space-around {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-space-evenly {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.centered-text {
    text-align: center;
}

.lighter {
    font-weight: lighter !important;
}

.ui.message {
    font-weight: normal;
}

.ui.label {
    font-weight: normal;
}

.horizontal-padding {
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.ui.form .field > label {
    font-weight: normal !important;
}

.ui.header {
    margin-bottom: 1.5rem;
    font-weight: 500 !important;
    font-family: 'Roboto', sans-serif;
}

/* TABLE */

.editable-table {
    font-size: 12px !important;
    font-weight: lighter !important;
}

.editable-table.error {
    border: 1px red solid;
}

table.editable-table tr, table.editable-table th, table.editable-table td {
    font-weight: normal;
}

.table-header {
    font-weight: 500 !important;
}

th.center {
    text-align: center !important;
}

.table-cell {
    padding: 0 !important;
}

.table-cell-result {
    text-align: center !important;
}

.table-cell-result-label {
    color: rgba(0,0,0,0.87);
    font-size: 11px;
    font-weight: 500;
}

.table-cell-label {
    font-size: 11px;
}

.table-cell > .ui.input {
    width: 100% !important;
}

.table-cell-input {
    text-align: center !important;
    border: none !important;
    border-bottom: none !important;
    font-size: 11px !important;
    height: 37px !important;
    width: 100% !important;
    padding: 0 !important;
}

.table-cell-input:focus {
    border: 1px solid #85B7D9 !important;
}

.table-cell-dropdown {
    width: 100% !important;
    border: none !important;
    padding-top: 0.905714em !important;
}

.table-cell-dropdown.multiple.selection {
    padding-top: 0.475714em !important;
    padding-bottom: 0 !important;
    padding-left: 1em !important;
}

.table-cell-dropdown .menu > .item {
    font-size: 1em !important;
}

.table-cell-dropdown:focus {
    -webkit-box-shadow: 0 0 8px #ED8031 !important;
    box-shadow: 0 0 8px #ED8031 !important;
    border: 1px solid #ED8031 !important;
}

.actions-cell {
    text-align: center !important;
    padding: 0 !important;
}

.actions-cell > * {
    display: none !important;
}

.actions-cell.touch-device > * {
    display: inline-block !important;
}

th.actions-cell > * {
    display: inline-block !important;
}

tr:hover .actions-cell > * {
    display: inline-block !important;
}

/* Full calendar */

body .fc {
    /*TODO*/
    /*overflow: auto !important;*/
}

.fc-view > table {
    overflow: auto !important;
}

.fc-title {
    white-space: pre-wrap;
}

.fc-time-grid table tbody tr {
    height: 22px;
}

/* Semantic */

.ui.dropdown .remove.icon {
    height: initial !important;
    width: auto !important;
}

.ui.search.dropdown.active > .text, .ui.search.dropdown.visible > .text {
}

.ui.styled.accordion.lighter-accordion .title {
    color: rgba(191, 191, 191, 0.87);
    font-weight: normal;
}
.ui.styled.accordion.lighter-accordion .title:hover, .ui.styled.accordion.lighter-accordion .title.active {
    color: rgba(0, 0, 0, 0.95)
}

.ui.styled.accordion.lighter-accordion .content {
}

.ui.search .prompt {
    border-radius: 500rem !important;
}

.ui.borderless.button {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ui.borderless.button:hover {
    background-color: transparent !important;
}

.scrolling.content {
    overflow-y: overlay !important;
}

.ui.basic.grey.button {
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.22) inset !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.22) inset !important;
    /*border: 1px solid rgba(0, 0, 0, 0.22);*/
}


/*.ui.basic.button:hover*/

.small.header {
    font-weight: normal !important;
    font-size: 1.3em !important;
    font-family: 'Roboto', sans-serif;
}

/* Phone */
.absolute-phone-hint,
.phone-hint {
    left: 5px;
    color: rgba(0, 0, 0, 0.22);
    font-size: 0.9em;
}

.absolute-phone-hint {
    position: absolute;
    top: 41px;
}

.phone-hint {
    position: relative;
    top: 3px;
}

.ui.dropdown .menu > .item {
    font-size: 0.85rem;
    font-weight: normal;
}

.ui.multiple.dropdown > .label {
    font-size: 0.9em;
}

.w80 {
    width: 80% !important;
}

.ui.attached.segment {
    padding: 20px 30px 20px !important;
}

.ui.attached.segment > .ui.form {
    padding: 20px !important;
}

.ui.popup.fitted {
    padding: 0 !important;
}

.ui.basic.light-red.button {
    -webkit-box-shadow: 0 0 0 1px #ad3f43 inset !important;
    box-shadow: 0 0 0 1px #ad3f43 inset !important;
    color: #ad3f43 !important
}

.ui.basic.light-red.button:hover {
    -webkit-box-shadow: 0 0 0 1px #e1474b inset !important;
    box-shadow: 0 0 0 1px #e1474b inset !important;
    color: #e1474b !important
}

.ui.basic.green.button {
    -webkit-box-shadow: 0 0 0 1px #21BA45 inset !important;
    box-shadow: 0 0 0 1px #21BA45 inset !important;
    color: #21BA45 !important;
}

.ui.basic.green.button:hover {
    -webkit-box-shadow: 0 0 0 1px #26e150 inset !important;
    box-shadow: 0 0 0 1px #26e150 inset !important;
    color: #26e150 !important;
}

.ui.basic.teal.button {
    -webkit-box-shadow: 0 0 0 1px #26A69A inset !important;
    box-shadow: 0 0 0 1px #26A69A inset !important;
    color: #26A69A !important
}

.ui.basic.teal.button:hover {
    -webkit-box-shadow: 0 0 0 1px #80CBC4 inset !important;
    box-shadow: 0 0 0 1px #80CBC4 inset !important;
    color: #80CBC4 !important
}

.status-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.status-button {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 5px !important;
}

i.fas.sem {
    margin: 0 .25rem 0 0;
}

i.fas.sem-x2 {
    margin: 0 .5rem 0 0;
}

.ui.vertical.menu .item > i.fas.sem {
    width: 1.18em;
    float: right;
    margin: 0 0 0 .5em;
    opacity: .9;
}

.ui.sub.header.key {
    margin-bottom: 0 !important;
    line-height: initial !important;
}

.ui.sub.header.key:not(:first-child) {
    margin-top: .44285714rem !important;
}


.ui.sub.header.column {
    text-align: center !important;
    margin: 0 !important;
    font-size: .95714286em !important;
}

.key-value-pair {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.key-value-pair > .key,
.key-value-pair > .value {
    margin: 0 !important;
}

.key-value-pair > .key {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: rgba(0, 0, 0, 0.57);
    padding-left: 5px !important;
    text-align: left;
}

.key-value-pair > .value {
    width: 60px;
    color: rgba(0, 0, 0, 0.57) !important;
    text-align: center;
    font-size: 0.95714286em !important;
}

.key-value-pair.emp > .key,
.key-value-pair.emp > .value {
    color: black !important;
}

.key-value-pair.detailing {
    margin-top: 12px;
    font-size: 0.85em;
}

.key-value-pair.detailing > .key {
    padding-left: 20px !important;
}

.key-value-pair.detailing > .value {
}

.key-value-pair .key.additional {
    margin: 0;
    /*padding-left: 20px !important; */
    font-size: 10px;
    color: rgba(0, 0, 0, 0.57);
}

.full-height-column {
    min-height: calc(100% - 10px) !important;
}

.selectable {
    cursor: pointer !important;
}

.summary-card-button {
    width: 70%;
    margin-bottom: 20px !important;
}

.summary-card-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px !important;
}

.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
    padding-top: 1px !important;
    padding-left: 1px !important;
}

.ui[class*="extra wide"].left.sidebar,
.ui[class*="extra wide"].right.sidebar {
    width: 675px;
}

/* TimeBot Buttons */

.tb-primary.button {
    min-width: 130px;
    color: white !important;
    background-color: #29abe2;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 inset, rgba(41, 171, 226, 0.3) 0 0 11px 2px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 inset, rgba(41, 171, 226, 0.3) 0 0 11px 2px;
}

.tb-primary.button:focus {
    background-color: #29abe2;
}

.tb-primary.button:hover {
    background-color: #29c8ff;
    font-weight: bold;
}

.tb-secondary.button {
    min-width: 130px;
    font-weight: normal !important;
    color: #62afeb !important;
    border: 1px solid #62afeb;
    background-color: transparent
}

.tb-secondary.button:hover {
    background-color: #62afeb;
    color: #fff !important;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 inset, rgba(41, 171, 226, 0.3) 0 0 11px 3px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 inset, rgba(41, 171, 226, 0.3) 0 0 11px 3px;
}

/* SUMMARY GRID */

.ui.summary.grid {
    padding: 10px 0 5px 0;
}

.ui.summary.grid > .row {
    padding-top: 0.7rem;
    padding-bottom: 0.6rem;
}

.ui.summary.grid > .row.detailing {
    padding-top: 0.4rem;
}

.ui.summary.grid > .row.dividing {
    border-top: 1px solid rgba(0,0,0,.05)!important;
}

.ui.summary.grid > .row > .column:first-child {
    text-align: left;
}

.ui.summary.grid > .row > .column:last-child {
    text-align: center;
}

.ui.summary.grid > .row > .column > h2.ui.sub.header {
    font-size: 0.9em;
    font-weight: 500;
}

.ui.summary.grid > .row.detailing > .column > h2.ui.sub.header {
    font-size: 0.8em;
    color: #000000b5;
}

.ui.summary.grid > .row.detailing > .column > h2.ui.sub.header.key {
    padding-left: 10px;
}

.ui.red.ribbon.label {
    border-color: #b21e1e!important;
}

.ui.red.label, .ui.red.labels .label {
    background-color: #db2828!important;
    border-color: #db2828!important;
    color: #fff!important;
}

.ui.red.labels .label:hover, a.ui.red.label:hover {
    background-color: #d01919!important;
    border-color: #d01919!important;
    color: #fff!important;
}

/* FONT AWESOME ICONS */

.ui.steps:not(.vertical) .step > i.fas {
    width: auto;
}
.ui.steps .step > .icon, .ui.steps .step > i.fas~.content {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -ms-flex-item-align: middle;
    -ms-grid-row-align: middle;
    align-self: middle;
    width: auto;
}
.ui.steps .step > i.fas {
    line-height: 1;
    font-size: 2.5em;
    margin: 0 1rem 0 0;
}

/* MODALS */

@media only screen and (min-width: 1200px) {
    .ui.large.modal {
        width: 1170px;
    }
}

.ui.page.modals.transition.visible {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.ui.page.dimmer.modals {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ui.page.dimmer.modals.active,
.ui.page.dimmer.modals.visible {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.ui.modal {
    position: relative !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.ui.modal > .content {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 !important;
    flex: 1 !important;
    overflow-x: hidden !important;
    /*max-height: initial !important;*/
}

.ui.longer.modal {
    /*height: 90%;*/
}


/*.ui.full-height.modal {*/
/*height: 90%;*/
/*width: 80%;*/
/*}*/

/*.ui.full-height.modal > .content {*/
/*height: 90%;*/
/*max-height: calc(75vh);*/
/*}*/


/* LINK BUTTON */ /* LINK STEP */

.link-btn,
.link-dropdown,
a.step {
    color: #0071bc !important;
    text-decoration: underline;
}

.link-btn:hover,
/*.link-btn.black:hover,*/
.link-dropdown:hover,
/*.link-dropdown.black:hover,*/
a.step:hover
/*a.step.black:hover */
{
    color: #29abe2 !important;
    cursor: pointer;
}

.link-btn.non-active,
.link-dropdown.non-active,
a.step.non-active {
    color: rgba(191,191,191,.87) !important;
}

.link-btn.secondary,
.link-dropdown.secondary,
a.step.secondary {
    text-decoration: none;
}

.link-btn.black,
.link-dropdown.black,
a.step.black {
    color: rgba(0,0,0,.87) !important;
}

.link-btn.info:hover,
.link-dropdown.info:hover,
a.step.info:hover {
    cursor: help;
}


.link-dropdown {
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

/* TOOLTIP ICON */

i.tooltip.icon {
    color: #0071bc;
}

i.tooltip.icon:hover {
    color: #29abe2;
    cursor: help;
}

/* SELECTION LIST */

.ui.selection.list .header {
    font-weight: 500 !important;
}

/*!* BLOCK UI *!*/

/*!*.blockUI.blockMsg.blockPage {*!*/
/*    !*height: 120px;*!*/
/*!*}*!*/

#observe-salon-dropdown {
    background: transparent;
    color: white !important;
    width: 300px !important;
    border: none !important;
    margin-top: -3px;
}

.field-description {
    color: #00000099;
    margin-bottom: 5px;
}

.help-accordion-3 {
    padding-bottom: 10px !important;
}

.start-video-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
}

.key-value-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 8px 10px 0;
    font-size: 0.9em;
    color: #00000087;
    line-height: 1.3;
}

.key-value-container > *:first-child {
    color: rgba(0, 0, 0, 0.84);
}

.key-value-container > *:last-child {
    text-align: right;
    max-width: 150px;
}



.close-popup-btn {
    color: grey;
    cursor: pointer;
    position: absolute;
    margin: 0;
    top: 0.5em;
    right: .5em;
    opacity: .7;
    -webkit-transition: opacity .1s ease;
    transition: opacity .1s ease;
}

